// import React from 'react';

// function HomePage() {
//   // Array of card data
//   const cards = [
//     { title: "Merge PDF", description: "Combine PDFs in the order you want.", icon: "📎" },
//     { title: "Split PDF", description: "Separate one page or a whole set.", icon: "✂️" },
//     { title: "Compress PDF", description: "Reduce file size while maintaining quality.", icon: "📉" },
//     { title: "PDF to Word", description: "Convert PDFs to editable DOCX files.", icon: "📄" },
//     { title: "PDF to PowerPoint", description: "Turn PDFs into PPT slides.", icon: "📊" },
//     { title: "PDF to Excel", description: "Pull data from PDFs into Excel sheets.", icon: "📊" },
//     { title: "Word to PDF", description: "Convert DOCX files to PDFs.", icon: "📃" },
//     { title: "Protect PDF", description: "Encrypt your PDFs for security.", icon: "🔒" },
//     // Add more cards as needed
//   ];

//   return (
//     <div className="min-h-screen bg-gray-100 p-6">
//       <h1 className="text-2xl font-bold text-gray-800 mb-6 flex items-center justify-center">PDF Tools</h1>
//       <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
//         {cards.map((card, index) => (
//           <div
//             key={index}
//             className="bg-white shadow-lg rounded-lg p-4 hover:shadow-xl transition"
//           >
//             <div className="flex items-center justify-center h-12 w-12 bg-blue-500 text-white rounded-md mb-4">
//               <span className="text-2xl">{card.icon}</span>
//             </div>
//             <h3 className="text-lg font-semibold text-gray-800">{card.title}</h3>
//             <p className="text-sm text-gray-600 mt-2">{card.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default HomePage;


import React from 'react';
import { Link } from 'react-router-dom';

function HomePage() {
  // Array of card data with routes
  const cards = [
    { title: "Merge PDF", description: "Combine PDFs in the order you want.", icon: "📎", route: "/merge-pdf" },
    { title: "Split PDF", description: "Separate one page or a whole set.", icon: "✂️", route: "/split-pdf" },
    { title: "Compress PDF", description: "Reduce file size while maintaining quality.", icon: "📉", route: "/compress-pdf" },
    { title: "PDF to Word", description: "Convert PDFs to editable DOCX files.", icon: "📄", route: "/pdf-to-word" },
    { title: "PDF to PowerPoint", description: "Turn PDFs into PPT slides.", icon: "📊", route: "/pdf-to-powerpoint" },
    { title: "PDF to Excel", description: "Pull data from PDFs into Excel sheets.", icon: "📊", route: "/pdf-to-excel" },
    { title: "Word to PDF", description: "Convert DOCX files to PDFs.", icon: "📃", route: "/word-to-pdf" },
    { title: "Protect PDF", description: "Encrypt your PDFs for security.", icon: "🔒", route: "/protect-pdf" },
    { title: "Word to PDF", description: "Convert DOCX files to PDFs.", icon: "📃", route: "/word-to-pdf" },
    { title: "Protect PDF", description: "Encrypt your PDFs for security.", icon: "🔒", route: "/protect-pdf" },
    // Add more cards as needed
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1 className="text-2xl font-bold text-gray-800 mb-6 flex  justify-center items-center">All are 100% FREE and easy to use</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-1">
        {cards.map((card, index) => (
          <Link 
            to={card.route} 
            key={index} 
            className="bg-white shadow-lg rounded-sm p-4 hover:shadow-xl transition block"
          >
            <div className="flex items-center justify-center h-12 w-12 bg-blue-500 text-white rounded-md mb-4">
              <span className="text-2xl">{card.icon}</span>
            </div>
            <h3 className="text-lg font-semibold text-gray-800">{card.title}</h3>
            <p className="text-sm text-gray-600 mt-2">{card.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
