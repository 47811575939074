import React from 'react'

function AboutPage() {
  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <h1>about page</h1>
    </div>
  )
}

export default AboutPage