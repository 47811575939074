import React from "react";

export default function Footer() {
  return (
    <footer className="bg-white text-black py-6 shadow-2xl border-t">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-center items-center">
          {/* Left section (e.g., logo or text) */}
          <div className="mb-4 md:mb-0">
            <p className="text-sm">© 2024 freesoftlover.xyz</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
