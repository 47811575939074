// import React, { useState, useRef } from "react";
// import axios from "axios";

// function MergePdf() {
//   const [files, setFiles] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadStatus, setUploadStatus] = useState("idle");
//   const [errorMessage, setErrorMessage] = useState(null);
//   const dragItem = useRef(null);
//   const dragOverItem = useRef(null);

//   // Handle file selection (click or drag & drop)
//   const handleFileChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles((prevFiles) => [
//       ...prevFiles,
//       ...selectedFiles.filter(
//         (file) => !prevFiles.some((f) => f.name === file.name),
//       ),
//     ]);
//   };

//   // Handle file removal
//   const handleRemoveFile = (fileName) => {
//     setFiles((prevFiles) =>
//       prevFiles.filter((file) => file.name !== fileName),
//     );
//   };

//   // Handle drag start to set the drag state
//   const handleDragStart = (e, position) => {
//     dragItem.current = position;
//   };

//   // Handle drag enter
//   const handleDragEnter = (e, position) => {
//     dragOverItem.current = position;
//   };

//   // Handle drag end to reorder
//   const handleDragEnd = (e) => {
//     const items = [...files];
//     const draggedItemContent = items.splice(dragItem.current, 1)[0];
//     items.splice(dragOverItem.current, 0, draggedItemContent);

//     dragItem.current = null;
//     dragOverItem.current = null;
//     setFiles(items);
//   };

//   // Handle file upload with Axios and progress feedback
//   const handleUpload = async () => {
//     if (files.length === 0) {
//       setErrorMessage("Please select at least one PDF file.");
//       setTimeout(() => {
//         setErrorMessage(null);
//       }, 3000);
//       return;
//     }

//     try {
//       setUploadStatus("progress");
//       const formData = new FormData();
//       files.forEach((file) => {
//         formData.append("files", file);
//       });

//       const config = {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//         responseType: "blob", 
//         onUploadProgress: (progressEvent) => {
//           if (progressEvent.lengthComputable) {
//             const progress = Math.round(
//               (progressEvent.loaded / progressEvent.total) * 100,
//             );
//             setUploadProgress(progress);
//           }
//         },
//       };

//       const response = await axios.post(
//         "http://localhost:8081/merge-pdf",
//         formData,
//         config,
//       );

//       console.log("Upload successful:", response.data);
//       setUploadStatus("success");
//     } catch (error) {
//       console.error("Upload failed:", error);
//       setUploadStatus("error");
//     }
//   };

//   return (
//     <div className="min-h-screen bg-green-50 py-10 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
//         <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
//           Merge PDF Files
//         </h1>

//         {/* Error Message */}
//         {errorMessage && (
//           <div className="text-red-500 text-center mt-4">{errorMessage}</div>
//         )}

//         {/* File Upload Section */}
//         <div
//           className="border-dotted border-4 border-green-500 p-6"
//           onDragOver={(e) => e.preventDefault()}
//         >
//           <div className="text-center mb-6">
//             <label className="block p-6 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition">
//               <input
//                 type="file"
//                 multiple
//                 accept=".pdf"
//                 onChange={handleFileChange}
//                 className="hidden"
//               />
//               <div className="flex justify-center items-center space-x-2">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   className="w-6 h-6 text-gray-600"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M12 4v16m8-8H4"
//                   />
//                 </svg>
//                 <span className="text-sm text-gray-600">
//                   Drag and drop PDF files here or click to select
//                 </span>
//               </div>
//             </label>
//           </div>

//           {/* File List */}
//           <div className="space-y-4">
//             {files.map((file, index) => (
//               <div
//                 key={file.name}
//                 className="flex justify-between items-center bg-gray-100 rounded-lg p-4 border border-dotted border-green-500 cursor-move"
//                 draggable
//                 onDragStart={(e) => handleDragStart(e, index)}
//                 onDragEnter={(e) => handleDragEnter(e, index)}
//                 onDragEnd={handleDragEnd}
//                 onDragOver={(e) => e.preventDefault()}
//               >
//                 <span className="mr-2 text-sm font-semibold text-gray-700">
//                   {file.name}
//                 </span>

//                 {/* Progress Bar */}
//                 {(uploadStatus === "progress" ||
//                   uploadStatus === "success" ||
//                   uploadStatus === "error") && (
//                   <div className="w-32 h-4 bg-white rounded-full overflow-hidden border-2 border-blue-100">
//                     <div
//                       className={`h-full ${
//                         uploadStatus === "success"
//                           ? "bg-green-500"
//                           : uploadStatus === "error"
//                           ? "bg-red-500"
//                           : "bg-yellow-400"
//                       }`}
//                       style={{
//                         width: `${uploadProgress}%`,
//                         transition: "width 0.3s ease-in-out",
//                       }}
//                     ></div>
//                   </div>
//                 )}

//                 <button
//                   className="ml-4 text-red-500"
//                   onClick={() => handleRemoveFile(file.name)}
//                 >
//                   Remove
//                 </button>
//               </div>
//             ))}
//           </div>

//           {/* Upload Button */}
//           <div className="text-center mt-6">
//             <button
//               onClick={handleUpload}
//               className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
//               disabled={uploadStatus === "progress"}
//             >
//               Merge Files
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MergePdf;




// import React, { useState, useRef } from "react";
// import axios from "axios";

// function MergePdf() {
//   const [files, setFiles] = useState([]);
//   const [uploadProgress, setUploadProgress] = useState(0);
//   const [uploadStatus, setUploadStatus] = useState("idle");
//   const [errorMessage, setErrorMessage] = useState(null);
//   const dragItem = useRef(null);
//   const dragOverItem = useRef(null);

//   const handleFileChange = (e) => {
//     const selectedFiles = Array.from(e.target.files);
//     setFiles((prevFiles) => [
//       ...prevFiles,
//       ...selectedFiles.filter(
//         (file) => !prevFiles.some((f) => f.name === file.name),
//       ),
//     ]);
//   };

//   const handleRemoveFile = (fileName) => {
//     setFiles((prevFiles) =>
//       prevFiles.filter((file) => file.name !== fileName),
//     );
//   };

//   const handleDragStart = (e, position) => {
//     dragItem.current = position;
//   };

//   const handleDragEnter = (e, position) => {
//     dragOverItem.current = position;
//   };

//   const handleDragEnd = (e) => {
//     const items = [...files];
//     const draggedItemContent = items.splice(dragItem.current, 1)[0];
//     items.splice(dragOverItem.current, 0, draggedItemContent);

//     dragItem.current = null;
//     dragOverItem.current = null;
//     setFiles(items);
//   };

//   const handleUpload = async () => {
//     if (files.length === 0) {
//       setErrorMessage("Please select at least one PDF file.");
//       setTimeout(() => {
//         setErrorMessage(null);
//       }, 3000);
//       return;
//     }

//     try {
//       setUploadStatus("progress");
//       const formData = new FormData();
//       files.forEach((file) => {
//         formData.append("files", file);
//       });

//       const config = {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//         responseType: "blob",
//         onUploadProgress: (progressEvent) => {
//           if (progressEvent.lengthComputable) {
//             const progress = Math.round(
//               (progressEvent.loaded / progressEvent.total) * 100,
//             );
//             setUploadProgress(progress);
//           }
//         },
//       };

//       const response = await axios.post(
//         "http://localhost:8081/merge-pdf", // Replace with your backend API endpoint
//         formData,
//         config,
//       );

//       // Create download link from response
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       link.setAttribute("download", "merged.pdf");
//       document.body.appendChild(link);
//       link.click();

//       setUploadStatus("success");
//     } catch (error) {
//       console.error("Upload failed:", error);
//       setUploadStatus("error");
//     }
//   };

//   return (
//     <div className="min-h-screen bg-green-50 py-10 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
//         <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
//           Merge PDF Files
//         </h1>

//         {/* Error Message */}
//         {errorMessage && (
//           <div className="text-red-500 text-center mt-4">{errorMessage}</div>
//         )}

//         <div
//           className="border-dotted border-4 border-green-500 p-6"
//           onDragOver={(e) => e.preventDefault()}
//         >
//           <div className="text-center mb-6">
//             <label className="block p-6 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition">
//               <input
//                 type="file"
//                 multiple
//                 accept=".pdf"
//                 onChange={handleFileChange}
//                 className="hidden"
//               />
//               <div className="flex justify-center items-center space-x-2">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   stroke="currentColor"
//                   className="w-6 h-6 text-gray-600"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M12 4v16m8-8H4"
//                   />
//                 </svg>
//                 <span className="text-sm text-gray-600">
//                   Drag and drop PDF files here or click to select
//                 </span>
//               </div>
//             </label>
//           </div>

//           <div className="space-y-4">
//             {files.map((file, index) => (
//               <div
//                 key={file.name}
//                 className="flex justify-between items-center bg-gray-100 rounded-lg p-4 border border-dotted border-green-500 cursor-move"
//                 draggable
//                 onDragStart={(e) => handleDragStart(e, index)}
//                 onDragEnter={(e) => handleDragEnter(e, index)}
//                 onDragEnd={handleDragEnd}
//                 onDragOver={(e) => e.preventDefault()}
//               >
//                 <span className="mr-2 text-sm font-semibold text-gray-700">
//                   {file.name}
//                 </span>

//                 {/* Progress Bar */}
//                 {(uploadStatus === "progress" ||
//                   uploadStatus === "success" ||
//                   uploadStatus === "error") && (
//                   <div className="w-32 h-4 bg-white rounded-full overflow-hidden border-2 border-blue-100">
//                     <div
//                       className={`h-full ${
//                         uploadStatus === "success"
//                           ? "bg-green-500"
//                           : uploadStatus === "error"
//                           ? "bg-red-500"
//                           : "bg-yellow-400"
//                       }`}
//                       style={{
//                         width: `${uploadProgress}%`,
//                         transition: "width 0.3s ease-in-out",
//                       }}
//                     ></div>
//                   </div>
//                 )}

//                 <button
//                   className="ml-4 text-red-500"
//                   onClick={() => handleRemoveFile(file.name)}
//                 >
//                   Remove
//                 </button>
//               </div>
//             ))}
//           </div>

//           <div className="text-center mt-6">
//             <button
//               onClick={handleUpload}
//               className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
//               disabled={uploadStatus === "progress"}
//             >
//               Merge Files
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default MergePdf;


import React, { useState, useRef } from "react";
import axios from "axios";

function MergePdf() {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [errorMessage, setErrorMessage] = useState(null);
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [
      ...prevFiles,
      ...selectedFiles.filter(
        (file) => !prevFiles.some((f) => f.name === file.name),
      ),
    ]);
  };

  const handleRemoveFile = (fileName) => {
    setFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName),
    );
  };

  const handleDragStart = (e, position) => {
    dragItem.current = position;
  };

  const handleDragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const handleDragEnd = (e) => {
    const items = [...files];
    const draggedItemContent = items.splice(dragItem.current, 1)[0];
    items.splice(dragOverItem.current, 0, draggedItemContent);

    dragItem.current = null;
    dragOverItem.current = null;
    setFiles(items);
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setErrorMessage("Please select at least one PDF file.");
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
      return;
    }

    try {
      setUploadStatus("progress");
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const progress = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
            setUploadProgress(progress);
          }
        },
      };

      const response = await axios.post(
        "https://104.234.134.252:8089/v1/api/merge-pdf", // Replace with your backend API endpoint
        formData,
        config,
      );

      // Create download link from response
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "merged.pdf");
      document.body.appendChild(link);
      link.click();

      // Clear the file list after successful merge and download
      setFiles([]);
      setUploadStatus("success");
    } catch (error) {
      console.error("Upload failed:", error);
      setUploadStatus("error");
    }
  };

  return (
    <div className="min-h-screen bg-green-50 py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-semibold text-center text-gray-800 mb-6">
          Merge PDF Files
        </h1>

        {/* Error Message */}
        {errorMessage && (
          <div className="text-red-500 text-center mt-4">{errorMessage}</div>
        )}

        <div
          className="border-dotted border-4 border-green-500 p-6"
          onDragOver={(e) => e.preventDefault()}
        >
          <div className="text-center mb-6">
            <label className="block p-6 border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-blue-500 hover:bg-blue-50 transition">
              <input
                type="file"
                multiple
                accept=".pdf"
                onChange={handleFileChange}
                className="hidden"
              />
              <div className="flex justify-center items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-6 h-6 text-gray-600"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                <span className="text-sm text-gray-600">
                  Drag and drop PDF files here or click to select
                </span>
              </div>
            </label>
          </div>

          <div className="space-y-4">
            {files.map((file, index) => (
              <div
                key={file.name}
                className="flex justify-between items-center bg-gray-100 rounded-lg p-4 border border-dotted border-green-500 cursor-move"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragEnter={(e) => handleDragEnter(e, index)}
                onDragEnd={handleDragEnd}
                onDragOver={(e) => e.preventDefault()}
              >
                <span className="mr-2 text-sm font-semibold text-gray-700">
                  {file.name}
                </span>

                {/* Progress Bar */}
                {(uploadStatus === "progress" ||
                  uploadStatus === "success" ||
                  uploadStatus === "error") && (
                  <div className="w-32 h-4 bg-white rounded-full overflow-hidden border-2 border-blue-100">
                    <div
                      className={`h-full ${
                        uploadStatus === "success"
                          ? "bg-green-500"
                          : uploadStatus === "error"
                          ? "bg-red-500"
                          : "bg-yellow-400"
                      }`}
                      style={{
                        width: `${uploadProgress}%`,
                        transition: "width 0.3s ease-in-out",
                      }}
                    ></div>
                  </div>
                )}

                <button
                  className="ml-4 text-red-500"
                  onClick={() => handleRemoveFile(file.name)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>

          <div className="text-center mt-6">
            <button
              onClick={handleUpload}
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
              disabled={uploadStatus === "progress"}
            >
              Merge Files
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MergePdf;