import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-white text-black p-4 shadow-2xl border-b">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/">
          <h1 className="hover:text-red-500 transition-all duration-300"><span className="font-base font-sans">FReesoft</span><span className="font-bold font-sans">Lover</span></h1>
        </Link>

        {/* Desktop menu */}
        <nav className="hidden md:flex space-x-6">
          <Link
            to="/"
            className="hover:text-gray-300 text-xs font-bold font-sans"
          >
            MARGE PDF
          </Link>
          <Link
            to="/about"
            className="hover:text-gray-300 text-xs font-bold font-sans"
          >
            SPLIT PDF
          </Link>
          <Link
            to="/services"
            className="hover:text-gray-300 text-xs font-bold font-sans"
          >
            COMPRESS PDF
          </Link>
          <Link
            to="/contact"
            className="hover:text-gray-300 text-xs font-bold font-sans"
          >
            CONVERT PDF
          </Link>
          <Link
            to="/blog"
            className="hover:text-gray-300 text-xs font-bold font-sans"
          >
            ALL PDF TOOLS
          </Link>
        </nav>

        {/* Hamburger icon for mobile */}
        <button onClick={toggleMenu} className="md:hidden text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* Sidebar for mobile */}
      <div
        className={`fixed top-0 left-0 w-2/3 h-full bg-white text-black transform transition-transform duration-300 ease-in-out ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } md:hidden`}
      >
        <div className="p-6">
          <button onClick={toggleMenu} className="text-black">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="space-y-4 mt-8">
            <li>
              <Link
                to="/"
                className="hover:text-gray-300 text-xs font-bold font-sans"
              >
                MARGE PDF
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className="hover:text-gray-300 text-xs font-bold font-sans"
              >
                SPLIT PDF
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="hover:text-gray-300 text-xs font-bold font-sans"
              >
                COMPRESS PDF
              </Link>
            </li>

            <li>
              <Link
                to="/contact"
                className="hover:text-gray-300 text-xs font-bold font-sans"
              >
                CONVERT PDF
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                className="hover:text-gray-300 text-xs font-bold font-sans"
              >
                ALL PDF TOOLS
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
